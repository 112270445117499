/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import gsap from 'gsap';
import CustomEase from "@lib/gsap-pro/CustomEase";
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Draggable from "gsap/Draggable";
import LazyLoad from "vanilla-lazyload";

// import Parvus from "parvus";
gsap.registerPlugin(ScrollTrigger, CustomEase, ScrollToPlugin, Draggable);



/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookies';
import snake from '@components/google-map.js';


/*
|
| Importing Utils
|-------------------
*/
// import Config from '@utils/config.js';
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import home from '@pages/home.js';
import news from '@pages/archive-news.js';
import article from '@pages/single-news.js';
import projects from '@pages/archive-projects.js';
import contact from '@pages/contact.js';


/*
|
| Routing
|----------
*/
const routes = new Router([

  {
  'file': snake,
  'dependencies': [app]
  },

  {
    'file': main,
    'dependencies': [app, gsap, ScrollTrigger, Draggable, CustomEase, LazyLoad, CookieManager]
  },
  {
    'file': home,
    'dependencies': [app],
    'resolve': '#page-home'
  },
  {
    'file': news,
    'dependencies': [app],
    'resolve': '#archive-news'
  },
  {
    'file': article,
    'dependencies': [app, gsap, ScrollTrigger],
    'resolve': '#single-article'
  },
  {
    'file': projects,
    'dependencies': [app],
    'resolve': '#archive-projects'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

