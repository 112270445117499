/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, gsap, ScrollTrigger, Draggable, CustomEase, LazyLoad, CookieManager) => {
    /*
        |
        | Constants
        |-----------
        */
    const $window = $(window),
      $body = $("body"),
      $bodyOffset = $body.offset(),
      $preloader = $("#preloader"),
      $cookieBanner = $(".cookie-banner"),
      $cookieAccept = $cookieBanner.find(".item-accept"),
      $cookieRefuse = $cookieBanner.find(".item-refuse"),
      $header = $("header"),
      $burger = $(".burger"),
      $scrollTo = $(".scrollto"),
      $scrollUp = $(".scrollup"),
      $themeElement = $("[data-theme]"),
      $themeColor = $(".theme-color"),
      $colorSpan = $themeColor.find("span");
    /*
        |
        | Easings
        |----------
        */
    CustomEase.create("easeCustom", "M0,0 C0.4,0 0.2,1 1,1");
    CustomEase.create("easeSmooth", "M0,0 C0.19,1 0.22,1 1,1");

    /*
        |
        | Loader
        |---------
        */
    $themeElement.attr("data-theme", sessionStorage.theme);

    if (sessionStorage.getItem("loaded_once") === null) {
      sessionStorage.setItem("loaded_once", "loaded_once");
      $preloader.find(".preloader-full").addClass("active");

      $window.on("load", function () {
        gsap.to($preloader, 0.2, {
          opacity: 0,
          ease: "Linear.easeNone",
          delay: 2.4,
          onComplete: () => $preloader.remove(),
          addCallback: () => app.dispachEvent($body, "loaderEnd"),
        });
      });
    } else {
      $window.on("load", function () {
        gsap.to($preloader, 0.2, {
          opacity: 0,
          ease: "Linear.easeNone",
          onComplete: () => $preloader.remove(),
          addCallback: () => app.dispachEvent($body, "loaderEnd"),
        });
      });
    }

    /*
        |
        | On Window Loaded
        |-----------------
        */
    $body.on("loaderEnd", () => {
      /*
            |
            | LazyLoad
            |-----------------
            */
      function lazyParent(el) {
        const lazyContainer = el.closest(".lazy-container");
        lazyContainer.classList.add("is-load");
      }

      window.lazy = new LazyLoad({
        unobserve_entered: true,
        callback_loaded: lazyParent,
      });

      /*
            |
            | Burger Menu
            |-----------------
            */
      $burger.on("click", function () {
        $body.toggleClass("is-menu");
      });

      function checkDevice() {
        if ("matchMedia" in window) {
          if (window.matchMedia("(min-width:48rem)").matches) {
            $body.removeClass("is-menu");
          }
        }
      }
      window.addEventListener("resize", checkDevice, false);

      /*
            |
            | Horloge et date
            |-----------------
            */

      const baliseH = document.getElementById("hourly");
      const baliseD = document.getElementById("date");

      function afficherHorloge() {
        const date = new Date();
        const h = date.getHours();
        const m = date.getMinutes();
        const jourNumero = date.getDate();
        const jourNom = date.toLocaleString("fr-FR", { weekday: "short" }); // Obtient le nom complet du jour de la semaine
        const mois = date.toLocaleString("fr-FR", { month: "short" }).trim();
        const annee = date.getFullYear();

        const heureMinute = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`;
        const dateText = `${jourNom} ${jourNumero < 10 ? "0" + jourNumero : jourNumero} ${mois} 1986`;

        baliseH.innerText = heureMinute;
        baliseD.innerText = dateText;
      }

      afficherHorloge();
      setInterval(afficherHorloge, 1000);

      /*
            |
            | Fullscreen
            |-----------------
            */

      const Fullscreen = document.getElementById("fullscreen");

      // Fonction pour basculer en plein écran
      function enterFullscreen() {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          // Firefox
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          // Chrome, Safari et Opera
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          // IE/Edge
          document.documentElement.msRequestFullscreen();
        }
      }

      // Fonction pour sortir du mode plein écran
      function exitFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari et Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
      }

      // Gérer le clic sur le bouton fullscreen
      Fullscreen.addEventListener("click", function () {
        if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
          // Si déjà en mode plein écran, sortir du mode plein écran
          exitFullscreen();
        } else {
          // Sinon, passer en mode plein écran
          enterFullscreen();
        }
      });

      /*
            |
            | Draggable stickers
            |----------------------
            */

      let sticker = document.querySelectorAll(".drag");

      Draggable.create(sticker, {
        bounds: "main",
        overshootTolerance: 1,
        inertia: true,
        allowEventDefault: true,
        allowContextMenu: true,
      });

      /*
            |
            | Pop in effect
            |----------------------
            */

      let highestZIndex = 1;

      // Fonction pour mettre à jour l'index Z de l'élément.
      function updateZIndex(element) {
        highestZIndex = getMaxZIndex() + 1;
        gsap.set(element, { zIndex: highestZIndex });
      }

      // Fonction pour obtenir l'index Z maximal parmi tous les pop-ins.
      function getMaxZIndex() {
        return Array.from(popIns).reduce((max, el) => Math.max(max, parseInt(window.getComputedStyle(el).zIndex) || 0), 0);
      }

      // Sélection de tous les éléments nécessaires.
      const containerFields = document.querySelectorAll(".files, .content");
      const popIns = document.querySelectorAll(".pop_in");
      const popInMap = {};
      const globalTimeline = gsap.timeline();

      // Création des timelines GSAP pour chaque pop-in.
      popIns.forEach((popIn) => {
        const popInIndex = popIn.getAttribute("data-pop-in");
        const timeline = gsap.timeline({ id: `TL${popInIndex}`, paused: true }).to(popIn, {
          scale: 1,
          yPercent: -50,
          onStart: () => updateZIndex(popIn),
        });

        popInMap[popInIndex] = timeline;

        popIn.addEventListener("click", (e) => {
          if (e.target.classList.contains("close")) {
            timeline.reverse();
            const iframeVimeo = popIn.querySelector("iframe");
            if (!!iframeVimeo) {
              const playerVimeo = new Vimeo.Player(iframeVimeo);
              playerVimeo.pause();
            }
          }
        });
      });

      // Associe chaque .file avec un pop-in correspondant.
      containerFields.forEach((container) => {
        const fields = container.querySelectorAll(".file");
        fields.forEach((field) => {
          const popInIndex = field.getAttribute("data-pop-in");
          if (popInIndex && !isNaN(popInIndex)) {
            const popInTimeline = popInMap[popInIndex];
            const popInElement = document.querySelector(`.pop_in[data-pop-in="${popInIndex}"]`); // Modification ici

            if (popInTimeline && popInElement) {
              globalTimeline.add(popInTimeline);
              field.addEventListener("click", () => {
                if (parseInt(window.getComputedStyle(popInElement).zIndex, 10) < getMaxZIndex()) {
                  updateZIndex(popInElement);
                }
                popInTimeline.play();
                const iframeVimeo = popInElement.querySelector("iframe");
                if (!!iframeVimeo) {
                  const playerVimeo = new Vimeo.Player(iframeVimeo);
                  playerVimeo.play();
                }
              });
            }
          }
        });
      });

      setTimeout(() => {
        let videoIcon = document.querySelector(".file_video");
        videoIcon.click();
      }, 500);

      /*
        |
        | Video player
        |----------------------
        */
    });
  },
};

