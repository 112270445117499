/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $loadMoreBtn = $('.load-more-btn'),
            $postsContainer = $('.loaded-posts'),
            $loadMoreContainer = $('.load-more-container'),
            $loadMore = $('.load-more'),
            $loadingContainer = $('.loading-container'),
            $total = $('.card-article').length,
            $limit = 9
            ;

        let xhr = null;

        if ($total < $limit) {
            $loadMoreContainer.hide()
        }
        /*
        |
        | Load more ajax
        |--------------
        */
        $loadMoreBtn.on('click', function (e) {

            abort(xhr)

            let offset = $postsContainer.find('.card-article').length
            let url = `/ajax/projects/${offset}/`

            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    $loadMore.hide()
                    $loadingContainer.show()
                },
                success: (response, status) => {

                    $postsContainer.append(response);
                    $loadingContainer.hide()

                    if (!$postsContainer.find('.no-more-post').length) {
                        $loadMoreContainer.show()
                        $loadMore.show()
                    } else {
                        $loadMoreContainer.hide()
                    }

                    xhr = null
                },
                complete: function (response) {

                    setTimeout(() => {
                        window.lazy.update()
                    }, 400)

                },
                error: (response, status, error) => {
                    console.log(error)
                }
            });

        });

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null) {
            if (xhrVar !== null) {
                xhrVar.abort()
            }

            if ($list !== null) {
                $list.find('.loading--ajax').hide()
            }
        }

    }
}
